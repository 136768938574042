<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="currentColor"
  >
    <path
      d="M1.74966 5.51372H14.2484M8.7802 1.38568L14.2484 5.51372L8.7802 9.64176"
      stroke="#191617"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
